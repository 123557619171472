import React, { useState, useContext, useEffect, useRef } from 'react';
import Axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { Player, Video } from '@vime/react';
import qs from 'query-string';
import {
  ClipboardCopyIcon,
  LoginIcon,
  UserAddIcon,
  DocumentAddIcon,
  PencilIcon,
} from '@heroicons/react/solid';
import { Editor } from '@tinymce/tinymce-react';
import {
  useAPI,
  Loader,
  RecordFunc,
  ViewContext,
  AuthContext,
  TwModal,
  Records,
  Tooltip,
  Message,
} from 'components/lib';
import emailToName from 'email-to-name';
import { useNavigate } from 'react-router-dom';

export function HuddleView() {
  const context = useContext(ViewContext);
  const auth = useContext(AuthContext);

  const [start, setStart] = useState(false);

  const [liveNotesContent, setLiveNotesContent] = useState('');
  const [savedNotesContent, setSavedNotesContent] = useState('');
  const [showNotes, setShowNotes] = useState(false);

  const [savedAgendaContent, setSavedAgendaContent] = useState('');
  const [liveAgendaContent, setLiveAgendaContent] = useState('');
  const [showAgenda, setShowAgenda] = useState(true);

  const notesEditorRef = useRef(null);
  const agendaEditorRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const isShare = location.pathname.split('/')[1] === 'share' ? true : false;

  let { id } = useParams();
  const queryStrings = qs.parse(id);
  const huddleId = Object.keys(queryStrings)[0];

  const getHuddleApi = `/api/huddle${isShare ? '/share' : ''}/${huddleId}`;

  const [participantEmailInput, setParticipantEmailInput] = useState(
    queryStrings.email
  );
  const [participantNameInput, setParticipantNameInput] = useState(
    participantEmailInput && emailToName.process(participantEmailInput)
  );
  const [participantEmail, setParticipantEmail] = useState(
    auth?.user?.email || queryStrings.email
  );
  const [participantName, setParticipantName] = useState(
    participantEmail &&
      emailToName.process(auth?.user?.name || participantEmail)
  );

  const data = useAPI(getHuddleApi);

  const recordsData = data?.data?.recordsData;
  const huddleData = data?.data?.huddleData;
  const date_created = huddleData?.date_created;
  const topic = huddleData?.topic;
  const goal = huddleData?.goal;
  const agenda = huddleData?.agenda;
  const notes = huddleData?.notes;
  let participants = huddleData?.participants;
  const ends = huddleData?.ends;
  const createdByEmail = huddleData?.createdByEmail;
  const isOwner = createdByEmail === auth?.user?.email;

  const onNotesSave = () => {
    let savedNotes;
    if (notesEditorRef.current) {
      savedNotes = notesEditorRef.current.getContent();
      setSavedNotesContent(savedNotes);
    }
    Axios.patch(`/api/huddle/${huddleId}`, { notes: savedNotes });
  };
  const onAgendaSave = () => {
    let savedAgenda;
    if (agendaEditorRef.current) {
      savedAgenda = agendaEditorRef.current.getContent();
      setSavedAgendaContent(savedAgenda);
    }
    Axios.patch(`/api/huddle/${huddleId}`, { agenda: savedAgenda });
  };

  const onNotesChange = () => {
    let savedNotes;
    if (notesEditorRef.current) {
      savedNotes = notesEditorRef.current.getContent();
      setLiveNotesContent(savedNotes);
    }
  };
  const onAgendaChange = () => {
    let savedAgenda;
    if (agendaEditorRef.current) {
      savedAgenda = agendaEditorRef.current.getContent();
      setLiveAgendaContent(savedAgenda);
    }
  };

  const inviteToHuddle = () => {
    context.modal.show(
      {
        title: `Add Invitees`,
        form: {
          id: {
            type: 'hidden',
            value: huddleId,
          },
          participants: {
            label: `Enter multiple email addresses (separated with a comma)`,
            type: 'textarea',
            required: true,
          },
        },
        buttonText: 'Invite',
        url: `/api/huddle/${huddleId}`,
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(
          `Participants have been invited`,
          'success',
          true
        );
        if (showInviteModal) {
          window.location.replace(
            `${window.location.protocol}//${window.location.host}/huddle/${huddleId}`
          );
          participants.push(res.data.data);
        } else {
          window.location.reload(false);
        }
      }
    );
  };

  const showInviteModal = queryStrings.invite;
  const showNewModal = queryStrings.new;

  useEffect(() => {
    if (huddleData && showInviteModal) {
      inviteToHuddle();
    }
  }, [participants, showInviteModal, huddleData, isOwner]);

  const editEnds = (ends) => {
    context.modal.show(
      {
        title: `Edit`,
        form: {
          id: {
            type: 'hidden',
            value: huddleId,
          },
          ends: {
            label: `Ends`,
            type: 'select',
            required: true,
            value: ends,
            options: [
              {
                label: '3 days',
                value: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000),
              },
              {
                label: '1 week',
                value: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
              },
              {
                label: '2 weeks',
                value: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
              },
            ],
          },
        },
        buttonText: 'Save',
        url: `/api/huddle/${huddleId}`,
        method: 'PATCH',
      },
      () => {
        context.notification.show(`Huddle was updated`, 'success', true);
        window.location.reload(false);
      }
    );
  };

  const editGoal = (goal) => {
    context.modal.show(
      {
        title: `Edit`,
        form: {
          id: {
            type: 'hidden',
            value: huddleId,
          },
          goal: {
            label: `Goal`,
            type: 'select',
            required: true,
            value: data.goal,
            options: [
              {
                label: 'Brainstorm',
                value: 'Brainstorm',
              },
              { label: 'Discuss', value: 'Discuss' },
              { label: 'Decide', value: 'Decide' },
            ],
          },
        },
        buttonText: 'Save',
        url: `/api/huddle/${huddleId}`,
        method: 'PATCH',
      },
      () => {
        context.notification.show(`Huddle was updated`, 'success', true);
        window.location.reload(false);
      }
    );
  };

  const editTopic = (topic) => {
    context.modal.show(
      {
        title: `Edit`,
        form: {
          id: {
            type: 'hidden',
            value: huddleId,
          },
          topic: {
            label: `Topic`,
            type: 'text',
            required: true,
            value: topic,
          },
        },
        buttonText: 'Save',
        url: `/api/huddle/${huddleId}`,
        method: 'PATCH',
      },
      () => {
        context.notification.show(`Huddle was updated`, 'success', true);
        window.location.reload(false);
      }
    );
  };

  const deleteHuddle = (huddleId) => {
    context.modal.show(
      {
        title: 'Delete Huddle',
        form: {
          id: {
            type: 'hidden',
            value: huddleId,
          },
        },
        buttonText: 'Delete Huddle',
        text: `Are you sure you want to delete huddle?`,
        url: `/api/huddle/${huddleId}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show('Huddle was deleted', 'success', true);
        window.location.replace(
          `${window.location.protocol}//${window.location.host}/huddles`
        );
      }
    );
  };

  return data.loading ? (
    <Loader />
  ) : start && participantEmail && participantName ? (
    <RecordFunc
      model="huddle"
      modelId={huddleId}
      participantName={participantName}
      topic={topic}
      goal={goal}
      agenda={agenda}
    />
  ) : (
    <div className="">
      {isShare && (!participantEmail || !participantName) && (
        <TwModal
          content={
            <div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-white-300"
                    placeholder="Your Email"
                    onChange={(e) => {
                      setParticipantEmailInput(e.target.value);
                      setParticipantNameInput(
                        emailToName.process(e.target.value)
                      );
                    }}
                  />
                </div>
                <button
                  onClick={() => {
                    setParticipantEmail(participantEmailInput);
                    setParticipantName(participantNameInput);
                    navigate(
                      `/share/huddle/${huddleId}&email=${participantEmailInput}`
                    );
                  }}
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-white-300 text-sm font-medium rounded-r-md text-white bg-blue-500 hover:bg-white-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                >
                  <LoginIcon className="h-6 w-5 text-blue-300" />
                  <span>Join</span>
                </button>
              </div>
            </div>
          }
          closeModal={() => setStart(false)}
        />
      )}

      {/* New Huddle */}
      {showNewModal === 'true' && (
        <Message title="Huddle is ready" type="success">
          <p>
            <b>Join</b> the huddle and <b>start recording</b>.{' '}
          </p>
          <p>
            Remember to <b>pin your first recording</b>, it will show up below
            the agenda.
          </p>
        </Message>
      )}

      {/* Huddle Information */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {topic}
            {!isShare && (
              <span className="ml-4 flex-shrink-0">
                <button
                  onClick={() => editTopic(topic)}
                  type="button"
                  className="text-sm rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  ✏️
                </button>
                <button
                  onClick={() => deleteHuddle(huddleId)}
                  type="button"
                  className="ml-4 text-sm rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  🗑️
                </button>
              </span>
            )}
          </h3>

          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Created {moment(date_created).calendar()}
          </p>

          <div className="flex justify-end -mt-10">
            <Tooltip text="Join the huddle and start recording">
              <button
                type="button"
                className="ml-4 inline-flex h-10 px-1.5 py-1.5 border border-transparent rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-lg"
                onClick={() =>
                  isShare
                    ? setStart(true)
                    : navigate(
                        `/share/huddle/${huddleId}&email=${participantEmail}`
                      )
                }
              >
                <LoginIcon className="h-7 w-5 text-blue-500 mr-2" />
                {isShare ? 'Join' : 'View'}
                <span className="-mt-4 -mr-2.5 relative inline-flex rounded-full h-4 w-4 bg-blue-500 animate-ping"></span>
              </button>
            </Tooltip>
            {!isShare && (
              <Tooltip text="An email link to this huddle will be sent">
                <button
                  type="button"
                  className="ml-4 inline-flex h-10 px-1.5 py-1.5 border border-transparent rounded shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm"
                  onClick={inviteToHuddle}
                >
                  <UserAddIcon className="h-6 w-5 text-gray-500 mr-2" />
                  <span className="mt-1">Invite</span>
                </button>
              </Tooltip>
            )}
            {isOwner && isShare && (
              <Tooltip text="Edit the huddle goal, agenda and more">
                <button
                  type="button"
                  className="ml-4 inline-flex h-10 px-1.5 py-1.5 border border-transparent rounded shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm"
                  onClick={() => navigate(`/huddle/${huddleId}`)}
                >
                  <PencilIcon className="h-6 w-5 text-gray-500 mr-2 mt-0.5" />
                  <span className="mt-1">Edit</span>
                </button>
              </Tooltip>
            )}
            <Tooltip text="Copies this huddle link for sharing">
              <button
                type="button"
                className="ml-4 inline-flex h-10 px-1.5 py-1.5 border border-transparent rounded shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${window.location.host}/share/huddle/${huddleId}`
                  )
                }
              >
                <ClipboardCopyIcon className="h-6 w-5 text-gray-500 mr-2 mt-0.5" />
                <span className="mt-1">Copy link</span>
              </button>
            </Tooltip>
          </div>
        </div>

        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Goal</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {goal}
                {!isShare && (
                  <span className="ml-4 flex-shrink-0">
                    <button
                      onClick={() => editGoal(goal)}
                      type="button"
                      className="text-md rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      ✏️
                    </button>
                  </span>
                )}
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Ends</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {ends ? moment(ends).calendar() : 'N.A.'}
                {!isShare && (
                  <span className="ml-4 flex-shrink-0">
                    <button
                      onClick={() => editEnds(ends)}
                      type="button"
                      className="text-md rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      ✏️
                    </button>
                  </span>
                )}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Invitees
                {!isShare && (
                  <span className="ml-4 flex-shrink-0">
                    <button
                      onClick={inviteToHuddle}
                      type="button"
                      className="text-mdrounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      ➕
                    </button>
                  </span>
                )}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {participants?.map((item) => item).join(', ')}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Agenda
                {!showAgenda && (
                  <ChevronDownIcon
                    className="h-4 w-4 cursor-pointer inline ml-1 -mt-1"
                    aria-hidden="true"
                    onClick={() => setShowAgenda(!showAgenda)}
                  />
                )}
                {showAgenda && (
                  <ChevronUpIcon
                    className="h-4 w-4 cursor-pointer inline ml-1 -mt-1"
                    aria-hidden="true"
                    onClick={() => setShowAgenda(!showAgenda)}
                  />
                )}
              </dt>
              <dd
                className={`mt-1 text-sm text-gray-900 ${
                  !isShare ? '' : 'pointer-events-none'
                }`}
              >
                {showAgenda ? (
                  <>
                    <Editor
                      apiKey="k8kdoio4fwqd3woe1acxlhsn4r3n6kmwmb47goyv2goiwena"
                      onInit={(evt, editor) =>
                        (agendaEditorRef.current = editor)
                      }
                      onEditorChange={onAgendaChange}
                      initialValue={agenda}
                      init={{
                        height: 400,
                        menubar: false,
                        statusbar: false,
                        content_style:
                          'body { font-family:Source Sans Pro,Helvetica,sans-serif; font-size:12.5px; }',
                        branding: false,
                        plugins: !isShare
                          ? [
                              'advlist',
                              'autolink',
                              'lists',
                              'link',
                              'image',
                              'charmap',
                              'preview',
                              'anchor',
                              'searchreplace',
                              'visualblocks',
                              'code',
                              'fullscreen',
                              'insertdatetime',
                              'media',
                              'table',
                              'code',
                              'wordcount',
                              'autosave',
                              'save',
                            ]
                          : [],
                        toolbar: !isShare
                          ? 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                          : '',
                      }}
                    />
                    {savedAgendaContent !== liveAgendaContent && (
                      <button
                        type="button"
                        className="inline-flex items-center p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-1 float-right"
                        onClick={onAgendaSave}
                      >
                        <DocumentAddIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Save
                      </button>
                    )}
                  </>
                ) : (
                  '......'
                )}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Pinned Recordings
              </dt>
              <dd className="p-4 max-w-7xl mx-auto md:grid md:grid-cols-2 text-sm text-gray-900 border border-gray-200 rounded-md">
                {recordsData?.map(
                  (record) =>
                    record.pinned && (
                      <div className="mx-2 md:flex md:flex-col">
                        <p className="font-semibold pb-2">{record.title}</p>
                        <Player controls>
                          <Video>
                            <source
                              data-src={record?.signedFileUrl}
                              type="video/mp4"
                            />
                          </Video>
                        </Player>
                      </div>
                    )
                )}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Notes
                {!showNotes && (
                  <ChevronDownIcon
                    className="h-4 w-4 cursor-pointer inline ml-1 -mt-1"
                    aria-hidden="true"
                    onClick={() => setShowNotes(!showNotes)}
                  />
                )}
                {showNotes && (
                  <ChevronUpIcon
                    className="h-4 w-4 cursor-pointer inline ml-1 -mt-1"
                    aria-hidden="true"
                    onClick={() => setShowNotes(!showNotes)}
                  />
                )}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {showNotes ? (
                  <Editor
                    apiKey="k8kdoio4fwqd3woe1acxlhsn4r3n6kmwmb47goyv2goiwena"
                    onInit={(evt, editor) => (notesEditorRef.current = editor)}
                    onEditorChange={onNotesChange}
                    initialValue={
                      notes
                        ? notes
                        : '<h3>Outcome:</h3><ul><li></li><li></li><li></li></ul><h3>Action Items:</h3><ul><li></li><li></li><li></li></ul>'
                    }
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'wordcount',
                        'autosave',
                        'save',
                      ],
                      toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style:
                        'body { font-family:Source Sans Pro,Helvetica,sans-serif; font-size:12.5px; background: #F3F4F6; }',
                      branding: false,
                    }}
                  />
                ) : (
                  '......'
                )}
                {savedNotesContent !== liveNotesContent && (
                  <button
                    type="button"
                    className="inline-flex items-center p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-1 float-right"
                    onClick={onNotesSave}
                  >
                    <DocumentAddIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Save
                  </button>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Records Information */}
      {isShare && recordsData && (
        <Records
          recordsData={recordsData}
          isShare={isShare}
          participantEmail={participantEmail}
          participantName={participantName}
        />
      )}
    </div>
  );
}
