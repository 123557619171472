/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional)
 *
 **********/

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Logo, Button, Icon, Link } from 'components/lib';
import './app.scss';

export function AppNav(props) {
  // state
  const [open, setOpen] = useState(false); // mobile is open
  const [expanded, setExpanded] = useState(false); // desktop is expanded

  return (
    <nav
      className={`sidebar popup ${open && 'open'} ${expanded && 'expanded'}`}
      onMouseEnter={(e) => setExpanded(true)}
      onMouseLeave={(e) => setExpanded(false)}
    >
      <Button
        icon={open ? 'x' : 'menu'}
        color={'dark'}
        size={12}
        className="btn-togglenav"
        action={(e) => setOpen(!open)}
      />
      <Link url="/dashboard">
        <Logo mark className="pt-1.5" icon />
      </Link>
      {expanded && (
        <Link
          color="white"
          className="ml-10 no-underline font-bold mt-0.5"
          url="/dashboard"
        >
          JumpAsync
        </Link>
      )}
      <section className="nav-links">
        <p
          className="m-2 pt-4 border-t border-white text-black font-bold sm:hidden"
          onClick={() => {
            window.open('/dashboard', '_self');
          }}
        >
          Dashboard
        </p>
        {/* Huddles */}
        <p className="m-2 py-4 h-10 text-white">
          {expanded && 'Group Huddles'}
        </p>
        <p className="m-2 pt-4 border-t border-white text-black font-bold sm:hidden">
          Group Huddles
        </p>
        {props.items?.slice(0, 2).map((item) => {
          if (item.link) {
            return (
              <NavLink
                key={item.label}
                to={item.link}
                style={{ width: 100 / props.items.length + '%' }}
              >
                {item.icon && (
                  <Icon
                    className="icon"
                    image={item.icon}
                    size={open ? 15 : 18}
                    color={open ? 'dark' : 'light'}
                  />
                )}
                {item.label && <span className="label">{item.label}</span>}
              </NavLink>
            );
          }
          return (
            <div key={item.label} onClick={item.action}>
              {item.icon && (
                <Icon
                  className="icon"
                  image={item.icon}
                  size={open ? 15 : 18}
                  color={open ? 'dark' : 'light'}
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </div>
          );
        })}

        {/* Recordings */}
        <p className="m-2 pt-4 h-10 border-t border-white text-white">
          {expanded && 'Personal Recordings'}
        </p>
        <p className="m-2 pt-4 border-t border-white text-black font-bold sm:hidden">
          Personal Recordings
        </p>
        {props.items?.slice(2, 4).map((item) => {
          if (item.link) {
            return (
              <NavLink
                key={item.label}
                to={item.link}
                style={{ width: 100 / props.items.length + '%' }}
              >
                {item.icon && (
                  <Icon
                    className="icon"
                    image={item.icon}
                    size={open ? 15 : 18}
                    color={open ? 'dark' : 'light'}
                  />
                )}
                {item.label && <span className="label">{item.label}</span>}
              </NavLink>
            );
          }
          return (
            <div key={item.label} onClick={item.action}>
              {item.icon && (
                <Icon
                  className="icon"
                  image={item.icon}
                  size={open ? 15 : 18}
                  color={open ? 'dark' : 'light'}
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </div>
          );
        })}

        {/* Account */}
        <p className="m-2 pt-4 h-10 border-t text-white">
          {expanded && 'Account'}
        </p>
        <p className="m-2 pt-4 border-t border-white text-black font-bold sm:hidden">
          Account
        </p>
        {props.items?.slice(4, 8).map((item) => {
          if (item.link) {
            return (
              <NavLink
                key={item.label}
                to={item.link}
                style={{ width: 100 / props.items.length + '%' }}
              >
                {item.icon && (
                  <Icon
                    className="icon"
                    image={item.icon}
                    size={open ? 15 : 18}
                    color={open ? 'dark' : 'light'}
                  />
                )}
                {item.label && <span className="label">{item.label}</span>}
              </NavLink>
            );
          }
          return (
            <div key={item.label} onClick={item.action}>
              {item.icon && (
                <Icon
                  className="icon"
                  image={item.icon}
                  size={open ? 15 : 18}
                  color={open ? 'dark' : 'light'}
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </div>
          );
        })}
      </section>
    </nav>
  );
}
