import { Fragment, useState, useContext, useRef } from 'react';
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  FireIcon,
  HeartIcon,
  ThumbUpIcon,
  ChatAlt2Icon,
} from '@heroicons/react/solid';
import { Listbox, Transition } from '@headlessui/react';
import Axios from 'axios';
import { TwCommentsList, ViewContext } from 'components/lib';

const moods = [
  {
    name: 'Hot',
    value: 'hot',
    icon: FireIcon,
    iconColor: 'text-white',
    bgColor: 'bg-red-500',
  },
  {
    name: 'Love',
    value: 'love',
    icon: HeartIcon,
    iconColor: 'text-white',
    bgColor: 'bg-pink-400',
  },
  {
    name: 'Happy',
    value: 'happy',
    icon: EmojiHappyIcon,
    iconColor: 'text-white',
    bgColor: 'bg-yellow-500',
  },
  {
    name: 'Sad',
    value: 'sad',
    icon: EmojiSadIcon,
    iconColor: 'text-white',
    bgColor: 'bg-gray-400',
  },
  {
    name: 'Like',
    value: 'like',
    icon: ThumbUpIcon,
    iconColor: 'text-white',
    bgColor: 'bg-blue-500',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function TwCommentInput({
  recordId,
  isShare,
  comments,
  participantName,
  participantEmail,
}) {
  const [selected, setSelected] = useState(moods[4]);
  const [showComments, setShowComments] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [commentsData, setCommentsData] = useState(comments);

  const context = useContext(ViewContext);

  const commentInput = useRef(null);

  const submitComment = (inputValue) => {
    Axios.patch(`/api/record${isShare ? '/share' : ''}/${recordId}`, {
      comment: inputValue,
      emoji: selected.value,
      participantName,
      participantEmail,
    }).then(async () => {
      const newComments = await Axios.get(
        `/api/record${isShare ? '/share' : ''}/${recordId} `
      );
      setCommentsData(newComments.data.data[0].comments);
      setInputValue('');
      commentInput.current.value = '';
      context.notification.show(
        'Your comment have been submitted successfully',
        'success',
        true
      );
    });
  };

  return (
    <>
      {!showComments && (
        <button
          onClick={() => {
            setShowComments(!showComments);
          }}
          type="button"
          className="h-8 w-8 border border-white-300 text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-white-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ml-auto mr-0 mt-1 float-right"
        >
          <ChatAlt2Icon
            className="h-6 w-6 cursor-pointer inline ml-auto"
            aria-hidden="true"
          />
        </button>
      )}

      {showComments && (
        <div
          onClick={() => {
            setShowComments(!showComments);
          }}
          className="text-xs mt-1 text-right cursor-pointer"
        >
          Hide ^
        </div>
      )}

      {showComments && (
        <>
          <div className="flex items-start space-x-4 mt-4">
            <div className="min-w-0 flex-1">
              <div className="relative">
                <div className="border rounded-md shadow-sm overflow-hidden focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
                  <label htmlFor="comment" className="sr-only">
                    Add your comment
                  </label>
                  <textarea
                    rows={2}
                    name="comment"
                    id="comment"
                    className="block w-full p-3 border-0 resize-none focus:ring-0 sm:text-sm"
                    placeholder="Post emoji or add your comment"
                    defaultValue={''}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                    ref={commentInput}
                  />

                  {/* Spacer element to match the height of the toolbar */}
                  <div className="py-2" aria-hidden="true">
                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>

                <div className="absolute bottom-0 inset-x-0 pl-3 py-2 flex justify-between">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="sr-only">
                              Your mood
                            </Listbox.Label>
                            <div className="relative">
                              <Listbox.Button className="relative -m-2.5 w-10 h-10 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500">
                                <span className="flex items-center justify-center">
                                  {selected?.value === null ? (
                                    <span>
                                      <EmojiHappyIcon
                                        className="flex-shrink-0 h-5 w-5"
                                        aria-hidden="true"
                                      />
                                      <span className="sr-only">
                                        Add your mood
                                      </span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span
                                        className={classNames(
                                          selected?.bgColor,
                                          'w-8 h-8 rounded-full flex items-center justify-center'
                                        )}
                                      >
                                        <selected.icon
                                          className="flex-shrink-0 h-5 w-5 text-white"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="sr-only">
                                        {selected?.name}
                                      </span>
                                    </span>
                                  )}
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute flex z-10 p-2 bg-white shadow rounded-lg text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:text-sm">
                                  {moods.map((mood) => (
                                    <Listbox.Option
                                      key={mood.value}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-gray-100' : 'bg-white',
                                          'cursor-default select-none relative p-1'
                                        )
                                      }
                                      value={mood}
                                    >
                                      <div className="flex items-center">
                                        <div
                                          className={classNames(
                                            mood.bgColor,
                                            'w-5 h-5 rounded-full flex items-center justify-center'
                                          )}
                                        >
                                          <mood.icon
                                            className={classNames(
                                              mood.iconColor,
                                              'flex-shrink-0 h-4 w-4'
                                            )}
                                            aria-hidden="true"
                                          />
                                        </div>
                                      </div>
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="submit"
                      onClick={() => submitComment(inputValue)}
                      className="inline-flex items-center mr-2 p-2 border border-transparent text-sm font-sm rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <TwCommentsList comments={commentsData} />
          </div>
        </>
      )}
    </>
  );
}
