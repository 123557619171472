import React, { useState, useContext } from 'react';
import { useAPI, ViewContext } from 'components/lib';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  ShareIcon,
  TrashIcon,
  ClipboardCopyIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/solid';
import { Player, Video } from '@vime/react';
import moment from 'moment';

const tabs = [{ name: 'Video' }, { name: 'Transcript' }];
const ICN_STYLE_SM = 'h-5 w-5 text-blue-500 mr-2';
const ICN_STYLE_SM_WHITE = 'h-5 w-5 text-white mr-2';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function RecordView(props) {
  const context = useContext(ViewContext);

  const [selectedTab, setSelectedTab] = useState('Video');

  const navigate = useNavigate();
  const location = useLocation();
  const isShare = location.pathname.split('/')[1] === 'share' ? true : false;

  let { id } = useParams();

  const data = useAPI(`/api/record${isShare ? '/share' : ''}/${id}`);

  const recordData = data?.data?.[0];
  const transcript = recordData?.transcript;
  const fileUrl = recordData?.signedFileUrl;
  const title = recordData?.title;
  const date_created = recordData?.date_created;
  const permission = recordData?.permission || 'private';

  const shareRecording = (data) => {
    context.modal.show(
      {
        title: 'Share Recording',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          permission: {
            label: `Permission for ${data.title}`,
            type: data.permission === 'owner' ? null : 'select',
            options: [
              {
                label: 'Public (anyone with the link can view)',
                value: 'public',
              },
              { label: 'Private (only you can view)', value: 'private' },
            ],
            default: permission,
          },
        },
        buttonText: 'Update',
        titleAddOn: permission === 'public' && (
          <div className="text-sm text-gray-400 flex">
            <p className="italic mt-2 text-gray">
              {window.location.host}/share/record/{data._id}
            </p>
            <button
              type="button"
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.host}/share/record/${data._id}`
                )
              }
              className="ml-2 h-8 w-4 pl-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <ClipboardCopyIcon className={ICN_STYLE_SM_WHITE} />
            </button>
          </div>
        ),
        url: `/api/record/${data._id}`,
        method: 'PATCH',
      },
      () => {
        context.notification.show(`Record was updated`, 'success', true);
        window.location.reload(false);
      }
    );
  };
  const deleteRecording = (data, callback) => {
    context.modal.show(
      {
        title: 'Delete Recording',
        form: {},
        buttonText: 'Delete',
        text: `Are you sure you want to delete recording '${data.title}'?`,
        url: `/api/record/${data._id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        navigate('../record');
        context.notification.show(
          `'${data.title}' recording was deleted`,
          'success',
          true
        );
      }
    );
  };

  if (data?.data?.length < 1) {
    return (
      <p>
        Sorry, the recording may be unavailable or you do not have permission to
        view this page.
      </p>
    );
  } else {
    return (
      <>
        <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
          <div className="md:flex md:items-center md:justify-between">
            <h2 className="text-xl leading-6 font-medium text-gray-900">
              {title}
            </h2>

            {permission === 'public' ? (
              <div className="inline-flex items-center text-lg">
                <button
                  type="button"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${window.location.host}/share/record/${id}`
                    )
                  }
                  className="mr-2 inline-flex pl-2 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm"
                >
                  <ClipboardCopyIcon className={ICN_STYLE_SM_WHITE} />
                </button>
                <button
                  type="button"
                  className="inline-flex px-1.5 py-1.5 border border-transparent font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm"
                  onClick={() => shareRecording(data?.data?.[0])}
                >
                  <EyeIcon className={ICN_STYLE_SM} />
                  Public (anyone with the link can view)
                </button>
              </div>
            ) : (
              <button
                type="button"
                onClick={() => shareRecording(data?.data?.[0])}
                className="inline-flex items-center px-1.5 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm"
              >
                <EyeOffIcon className={ICN_STYLE_SM} /> Private (only you can
                view)
              </button>
            )}

            {!isShare && (
              <div className="mt-8 flex md:mt-0 md:absolute md:top-8 md:right-0">
                <div className="pt-2">
                  <button
                    title="Share"
                    type="button"
                    onClick={() => shareRecording(data?.data?.[0])}
                    className="-ml-px relative inline-flex items-center pl-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <ShareIcon className={ICN_STYLE_SM} />
                  </button>
                  {/* <button
                    title="Download"
                    type="button"
                    className="-ml-px relative inline-flex items-center pl-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <DownloadIcon className={ICN_STYLE_SM} />
                  </button> */}
                  <button
                    title="Delete"
                    type="button"
                    onClick={() => deleteRecording(data?.data?.[0])}
                    className="-ml-px relative inline-flex items-center pl-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <TrashIcon className={ICN_STYLE_SM} />
                  </button>
                </div>
              </div>
            )}
          </div>
          <p className="mt-1.5 text-sm">
            Created {moment(date_created).calendar()}
          </p>
          <div className="mt-4">
            <div className="sm:hidden">
              <label htmlFor="current-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                defaultValue={selectedTab}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8 cursor-pointer">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      selectedTab === tab.name
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                    )}
                    onClick={() => setSelectedTab(tab.name)}
                  >
                    {tab.name}
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.current ? 'bg-blue-500' : 'bg-transparent',
                        'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
        {selectedTab === 'Video' && (
          <>
            <div className="w-full lg:w-3/5 pt-2">
              <Player controls>
                <Video>
                  <source data-src={fileUrl} type="video/mp4" />
                </Video>
              </Player>
            </div>
          </>
        )}
        {selectedTab === 'Transcript' && (
          <div className="max-w-prose pt-4">
            <p className="text-sm text-gray-500">
              {transcript ? transcript : 'No transcript available'}
            </p>
          </div>
        )}
      </>
    );
  }
}
