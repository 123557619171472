/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import React, { Fragment, useContext } from 'react';
import {
  AuthContext,
  Logo,
  Link,
  Button,
  Content,
  ClassHelper,
} from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props) {
  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {
    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent,
  });

  return (
    <div className="h-32 md:h-24 bg-blue-500">
      <section className={css}>
        <Content>
          <a href="https://jumpasync.com">
            <Logo className={Style.logo} />
          </a>
          <nav className="absolute md:top-1/2 md:-translate-y-1/2 right-0 md:pt-0 md:ml-0 z-20 mt-4">
            <Link
              url="https://jumpasync.com/blog"
              text="Blog"
              className={Style.link}
              color="white"
            />
            <Link
              url="https://jumpasync.com/pricing"
              text="Pricing"
              className={Style.link}
              color="white"
            />
            {context.user?.token ? (
              <Link
                url="/dashboard"
                text="Dashboard"
                className={Style.link}
                color="white"
              />
            ) : (
              <Fragment>
                <Link
                  url="/signin"
                  text="Sign In"
                  className={Style.link}
                  color="white"
                />
                <Button
                  small
                  goto="/signup"
                  text="Sign Up"
                  className="inline-block bg-blue-400"
                />
              </Fragment>
            )}
          </nav>
        </Content>
      </section>
    </div>
  );
}
