/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, User } from 'components/lib';
import Style from './app.module.scss';

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values// This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values// Call this function in your authentication promise handler or callback when your visitor and account id values are available// Please use Strings, Numbers, or Bools for value types.
  window.pendo.initialize({
    visitor: {
      id: context?.user?.email, // Required if user is logged in, default creates anonymous ID
      email: context?.user?.email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: context?.user?.name, // Recommended if using Pendo Feedback
      role: context?.user?.permission, // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: context?.user?.account_id, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      // name:         // Optional
      is_paying: context?.user?.plan === 'free' ? false : true, // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      planLevel: context?.user?.plan, // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });

  let menuItems = [
    // { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
    { label: 'Create ', icon: 'plus-square', link: '/huddle' },
    { label: 'Library ', icon: 'coffee', link: '/huddles' },
    { label: 'Create', icon: 'circle', link: '/record' },
    { label: 'Library ', icon: 'book', link: '/records' },
    // { label: 'Invite', icon: 'user-plus', link: '/account/users' },
    { label: 'Settings', icon: 'settings', link: '/account' },
    { label: 'Help', icon: 'help-circle', link: '/contact' },
  ];
  if (context.permission || context.user) {
    menuItems.push({
      label: 'Sign Out',
      icon: 'log-out',
      action: context.signout,
    });
  } else {
    menuItems.push({
      label: 'Sign In',
      icon: 'log-in',
      action: context.signout,
    });
  }

  return (
    <Fragment>
      <AppNav items={menuItems} />

      <main className={Style.app}>
        <Header title={props.title}>
          <User />
        </Header>

        <div className="pt-11">{<props.children {...props.data} />}</div>
      </main>
    </Fragment>
  );
}
