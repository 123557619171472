import React, { useState, useContext, useEffect, useRef } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import Webcam from 'react-webcam';
import Axios from 'axios';
import {
  VideoCameraIcon,
  StopIcon,
  EyeIcon,
  ShareIcon,
  DocumentAddIcon,
  ReceiptRefundIcon,
  DownloadIcon,
  ArrowRightIcon,
} from '@heroicons/react/solid';
import { v4 as uuidv4 } from 'uuid';
import createSpeechServicesPonyfill from 'web-speech-cognitive-services';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import moment from 'moment';
import { Player, Video } from '@vime/react';
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import { Editor } from '@tinymce/tinymce-react';
import { TwModal, ViewContext, AuthContext, Link } from 'components/lib';

const REGION = 'eastus';

export function RecordFunc({
  screen = true,
  audio = true,
  video = true,
  // askPermissionOnMount = true,
  downloadRecordingPath = `jumpasync-${uuidv4()}`,
  downloadRecordingType = 'mp4',
  topic,
  goal,
  agenda,
  model = 'record',
  modelId,
  participantEmail,
  participantName,
}) {
  // const [recordingNumber, setRecordingNumber] = useState(0);
  const [localStatus, setLocalStatus] = useState('');
  const [countdown, setCountdown] = useState('3');
  const [showModal, setShowModal] = useState(false);
  const context = useContext(ViewContext);
  const auth = useContext(AuthContext);
  const agendaEditorRef = useRef(null);

  const {
    status,
    startRecording: startRecord,
    stopRecording: stopRecord,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    screen,
    audio,
    video,
    //askPermissionOnMount
  });

  const stopRecording = () => {
    SpeechRecognition.stopListening();
    return stopRecord();
  };

  useEffect(() => {
    const loadSpeechRecognition = async () => {
      const authorizationToken = await Axios.get(`/api/auth/speech`).then(
        (res) => {
          return res.data.data;
        }
      );
      const { SpeechRecognition: AzureSpeechRecognition } =
        await createSpeechServicesPonyfill({
          credentials: {
            region: REGION,
            authorizationToken,
          },
        });
      SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
    };
    loadSpeechRecognition();

    if (auth?.user?.plan === 'free') {
      setTimeout(stopRecording, 300000);
    } else if (auth?.user?.plan === 'price_1KqrlfCye1XYEUBinOL6moIy') {
      setTimeout(stopRecording, 1500000);
    }
  }, [auth?.user?.plan]);

  const { transcript, finalTranscript, resetTranscript } =
    useSpeechRecognition();

  const fileName = `${downloadRecordingPath}.${downloadRecordingType}`;
  const title = `Recording - ${moment().format('DD MMM YYYY')}`;

  const startRecording = () => {
    resetTranscript();
    setCountdown('3');
    setLocalStatus('starting');
    setTimeout(() => {
      setCountdown('2');
    }, 1000);
    setTimeout(() => {
      setCountdown('1');
    }, 2000);
    return setTimeout(() => {
      startRecord();
      SpeechRecognition.startListening({
        continuous: true,
        language: 'en-US',
      });
      setCountdown('1');
      setLocalStatus('');
    }, 3000);
  };
  const viewRecording = () => {
    window.open(mediaBlobUrl, '_blank').focus();
  };
  const downloadRecording = () => {
    try {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        window.navigator.msSaveOrOpenBlob(mediaBlobUrl, fileName);
      } else {
        // for Chrome
        const link = document.createElement('a');
        link.href = mediaBlobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const shareRecording = () => {
    try {
      window.location.href = `mailTo:test?subject=Screen recording&body=Hello%20,%0D%0A%0D%0A${mediaBlobUrl}`;
    } catch (err) {
      console.error(err);
    }
  };
  const saveRecording = () => {
    context.modal.show({
      model: model,
      title: model === 'huddle' ? 'Publish to Huddle' : 'Save Recording',
      form: {
        title: {
          label: 'Title',
          type: 'text',
          required: true,
          value: title,
        },
      },
      buttonText: 'Save',
      action: async (titleValue) => {
        const mediaBlobFile = await fetch(mediaBlobUrl).then((r) => r.blob());
        const mediaFile = new File([mediaBlobFile], downloadRecordingPath, {
          type: 'video/mp4',
        });
        const formData = new FormData();

        formData.append('mediaFile', mediaFile);
        formData.append('fileName', fileName);
        formData.append('transcript', finalTranscript);
        formData.append('title', titleValue);
        let api;
        if (model === 'record') {
          api = '/api/record';
          await Axios.post(api, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then(() => {
            context.modal.hide(false);
            setShowModal(true);
          });
        } else if (model === 'huddle') {
          formData.append('huddleId', modelId);
          formData.append('participantEmail', participantEmail);
          formData.append('participantName', participantName);
          api = `/api/huddle/${modelId}`;
          await Axios.patch(api, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then(() => {
            context.modal.hide(false);
            setShowModal(true);
          });
        }
      },
    });
  };

  const BTN_STYLE =
    'mx-auto w-56 fixed inset-x-0 top-22 pl-4 py-2 inline-flex items-center border border-transparent rounded-full shadow-sm text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 mx-auto flex justify-center';
  const ICN_STYLE_SM = 'h-5 w-5 text-blue-500 mr-2';
  const ICN_STYLE_LG = 'h-10 w-10 mr-1 flex';

  console.log(status);

  return (
    <>
      {status &&
        status !== 'recording' &&
        status !== 'acquiring_media' &&
        localStatus !== 'starting' &&
        !mediaBlobUrl && (
          <>
            {auth?.user?.plan === 'free' && (
              <p className="text-sm text-center mb-4">
                Note that maximum recording duration is 5 minutes, please{' '}
                <Link url="/account/billing" target="_blank">
                  <a
                    href="/account/billing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    upgrade your plan
                  </a>
                </Link>{' '}
                to record for longer.
              </p>
            )}
            {auth?.user?.plan === 'price_1KqrlfCye1XYEUBinOL6moIy' && (
              <p className="text-sm text-center mb-4">
                Note that maximum recording duration is 25 minutes, please{' '}
                <Link url="/account/billing" target="_blank">
                  <a
                    href="/account/billing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    upgrade your plan
                  </a>
                </Link>{' '}
                to record for longer.
              </p>
            )}
            <button
              type="button"
              onClick={startRecording}
              className={BTN_STYLE}
            >
              <span className="mr-2">START RECORD</span>
              <VideoCameraIcon className={ICN_STYLE_LG} />
              <span className="-mt-12 -mr-6 relative inline-flex rounded-full h-3 w-3 bg-red-400 animate-ping"></span>
            </button>
          </>
        )}
      {localStatus === 'starting' && (
        <button
          type="button"
          className={`mx-auto fixed inset-x-0 top-22 pl-4 py-2 inline-flex items-center border border-transparent rounded-full shadow-sm text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 mx-auto animate-bounce w-16 h-16`}
        >
          <p className="font-bold text-2xl flex justify-center pl-2">
            {countdown}
          </p>
        </button>
      )}
      {status && status === 'recording' && (
        <>
          <button type="button" onClick={stopRecording} className={BTN_STYLE}>
            <StopIcon className="h-10 w-10 mr-1 flex" />
            <Timer active duration={null} className="pr-6 text-xl">
              <Timecode />
            </Timer>
          </button>
          <Webcam className="object-cover w-44 h-44 rounded-full fixed bottom-6 z-20" />
        </>
      )}
      {status === 'recording' && transcript && (
        <p className="prose fixed bottom-6 left-72 text-md">
          {transcript.split(' ').length > 25
            ? transcript.split(' ').splice(-25).join(' ')
            : transcript}
        </p>
      )}
      {mediaBlobUrl && status === 'stopped' && (
        <div className="w-full lg:w-3/5 pt-2">
          <Player controls>
            <Video>
              <source data-src={mediaBlobUrl} type="video/mp4" />
            </Video>
          </Player>
        </div>
      )}
      {mediaBlobUrl && status === 'stopped' && (
        <div className="pt-2">
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            onClick={viewRecording}
          >
            <EyeIcon className={ICN_STYLE_SM} />
            View
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            onClick={() => window.location.reload()}
          >
            <ReceiptRefundIcon className={ICN_STYLE_SM} />
            Record Again
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            onClick={saveRecording}
          >
            <DocumentAddIcon className={ICN_STYLE_SM} />
            {model === 'huddle' ? 'Publish to Huddle' : 'Save to Library'}
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            onClick={downloadRecording}
          >
            <DownloadIcon className={ICN_STYLE_SM} />
            Download
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            onClick={shareRecording}
          >
            <ShareIcon className={ICN_STYLE_SM} />
            Share
          </button>
        </div>
      )}
      {status === 'stopped' && finalTranscript && (
        <div className="py-6 ml-1/5">
          <h3 className="font-bold text-xl pt-4">Transcript</h3>
          <p className="prose pt-4">{finalTranscript}</p>
        </div>
      )}
      {showModal && (
        <TwModal
          title={
            model === 'huddle'
              ? 'Recording published to huddle.'
              : 'Recording saved to library.'
          }
          btnLeftLabel={model === 'huddle' ? 'Go to Huddle' : 'Go to Library'}
          btnLeftLink={model === 'huddle' ? '' : '/records'}
          btnRightLabel="Cancel"
          closeModal={() => setShowModal(false)}
          status="success"
        />
      )}
      {status !== 'stopped' && (
        <>
          {topic && (
            <h3 className="text-xl font-semibold leading-10 pt-6">{topic}</h3>
          )}
          {goal && (
            <p className="text-lg font-semibold leading-10">
              Hey {participantName}, let's {goal.toLowerCase()} about..
            </p>
          )}
          {agenda && (
            <p className="mb-6">
              <Editor
                apiKey="k8kdoio4fwqd3woe1acxlhsn4r3n6kmwmb47goyv2goiwena"
                onInit={(evt, editor) => (agendaEditorRef.current = editor)}
                initialValue={agenda}
                init={{
                  height: 400,
                  menubar: false,
                  toolbar: false,
                  statusbar: false,
                  content_style:
                    'body { font-family:Source Sans Pro,Helvetica,sans-serif; font-size:12.5px; }',
                  branding: false,
                }}
              />
            </p>
          )}
        </>
      )}
    </>
  );
}
