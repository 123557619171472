import { Home } from 'views/website/home';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: 'JumpAsync - Meetings without being online at the same time',
  },
  // {
  //   path: '/contact-us',
  //   view: Contact,
  //   layout: 'home',
  //   title: 'JumpAsync - Contact Us',
  // },
  // {
  //   path: '/pricing',
  //   view: Pricing,
  //   layout: 'home',
  //   title: 'JumpAsync - Simple pricing for an unlimited number of users',
  // },
  // {
  //   path: '/privacy',
  //   view: Privacy,
  //   layout: 'home',
  //   title: 'JumpAsync - Privacy Policy',
  // },
  // {
  //   path: '/terms',
  //   view: Terms,
  //   layout: 'home',
  //   title: 'JumpAsync - Terms & Conditions',
  // },
];

export default Routes;
