/***
 *
 *   ONBOARDING
 *   Flow to help users set up the app, accepts multiple views/steps
 *   On finish/cancel, the user will be marked as onboarded
 *
 *   PROPS
 *   views: array of views to render containing keys:
 *     name, description and component to render
 *
 **********/

import React, { useState, useContext } from 'react';
import Axios from 'axios';
import {
  Animate,
  CheckList,
  Button,
  Logo,
  useNavigate,
  Event,
  AuthContext,
} from 'components/lib';
import Style from './onboarding.tailwind.js';

export function Onboarding(props) {
  const navigate = useNavigate();
  const hash = window.location.hash;
  const auth = useContext(AuthContext);

  const [activeView, setActiveView] = useState(
    hash ? hash.substring(1) - 1 : 0
  );
  const [demoHuddleId, setDemoHuddleId] = useState('');

  if (!props?.views?.length) return false;

  const view = props.views[activeView];

  async function goTo(view) {
    window.location.hash = view + 1;
    setActiveView(view);
  }

  async function generateHuddles(view) {
    window.location.hash = view + 1;
    setActiveView(view);

    const { job } = props;
    console.log('job ', job);

    if (job) await Axios.patch('/api/user', { job });

    if (job === 'Software Engineer') {
      const res = await Axios.post('/api/huddle', {
        topic: 'Standup',
        goal: 'Discuss',
        ends: '3 days',
        createdByEmail: auth?.user?.email,
        createdByName: auth?.user?.name,
        agenda:
          '<ul class="i8Z77e">\n<li class="TrT0Xe">What did you do yesterday?</li>\n<li class="TrT0Xe">What will you do today?</li>\n<li class="TrT0Xe">What (if anything) is blocking your progress?</li>\n</ul>',
      });
      const huddleId = res.data.data;
      setDemoHuddleId(huddleId);
    } else if (job === 'Marketer') {
      const res = await Axios.post('/api/huddle', {
        topic: 'Marketing Strategy Meeting',
        goal: 'Discuss',
        ends: '3 days',
        createdByEmail: auth?.user?.email,
        createdByName: auth?.user?.name,
        agenda:
          '<ul class="i8Z77e">\n<li class="TrT0Xe">Define marketing strategy for this quarter</li>\n<li class="TrT0Xe">Go through quarterly goals</li>\n<li class="TrT0Xe">Address challenges</li>\n</ul>',
      });
      console.log('res ', res);
      const huddleId = res.data.data;
      setDemoHuddleId(huddleId);
    } else if (job === 'Recruiter') {
      const res = await Axios.post('/api/huddle', {
        topic: 'Initial Conversation with Recruiter',
        goal: 'Discuss',
        ends: '3 days',
        createdByEmail: auth?.user?.email,
        createdByName: auth?.user?.name,
        agenda:
          '<ul class="i8Z77e">\n<li class="TrT0Xe">Describe your current role</li>\n<li class="TrT0Xe">What are you looking for in your next role?</li>\n</ul>',
      });
      const huddleId = res.data.data;
      setDemoHuddleId(huddleId);
    } else if (job === 'Educator') {
      const res = await Axios.post('/api/huddle', {
        topic: 'Solve math problem',
        goal: 'Brainstorm',
        ends: '3 days',
        createdByEmail: auth?.user?.email,
        createdByName: auth?.user?.name,
        agenda:
          '<ul class="i8Z77e">\n<li class="TrT0Xe">What is the problem?</li>\n<li class="TrT0Xe">What are some of the ways to solve this?</li>\n<li class="TrT0Xe">Which is the most efficient solution?</li>\n</ul>',
      });
      const huddleId = res.data.data;
      setDemoHuddleId(huddleId);
    } else if (job === 'Designer') {
      const res = await Axios.post('/api/huddle', {
        topic: 'App Usability Interview',
        goal: 'Discuss',
        ends: '3 days',
        createdByEmail: auth?.user?.email,
        createdByName: auth?.user?.name,
        agenda:
          '<ul class="i8Z77e">\n<li class="TrT0Xe">What are your first impressions of the app?</li>\n<li class="TrT0Xe">What issues did you encounter whlist using the app?</li>\n<li class="TrT0Xe">What are some of the ways to improve the usability of the app?</li>\n</ul>',
      });
      const huddleId = res.data.data;
      setDemoHuddleId(huddleId);
    } else if (job === 'Other') {
      const res = await Axios.post('/api/huddle', {
        topic: 'Team Catchup',
        goal: 'Discuss',
        ends: '3 days',
        createdByEmail: auth?.user?.email,
        createdByName: auth?.user?.name,
        agenda:
          '<ul class="i8Z77e"><li class="TrT0Xe">What have you accomplished recently?</li>\n<li class="TrT0Xe">What are you working on next?</li>\n<li class="TrT0Xe">What are some of the challenges you have faced?</li>\n</ul>',
      });
      const huddleId = res.data.data;
      setDemoHuddleId(huddleId);
    }
  }

  async function finish() {
    if (props.save) await Axios.patch('/api/user', { onboarded: true });

    navigate(props.onFinish || '/dashboard');
  }

  return (
    <Animate type="pop-center">
      <div className={Style.onboarding}>
        <Button
          color="light"
          icon="arrow-right-circle"
          text="Skip Intro"
          position="absolute"
          alignRight
          className={Style.skip}
          size={16}
          action={() => {
            Event('cancelled_onboarding');
            finish();
          }}
        />

        <section className={Style.sidebar}>
          <Logo className={Style.logo} />

          <CheckList
            color="white"
            className={Style.checklist}
            hideCross
            interactive
            items={props.views.map((view, i) => {
              return {
                name: view.name,
                checked: i <= activeView ? true : false,
                onClick: () => goTo(i),
              };
            })}
          />
        </section>

        <section className={Style.main}>
          <header className={Style.header}>
            <h2 className={Style.name}>{view.name}</h2>

            {view.description && (
              <span className={Style.description}>{view.description}</span>
            )}
          </header>

          <article className={Style.article}>
            {view.component}
            {activeView === 1 && (
              <Button
                text="Try it out!"
                goto={`/share/huddle/${demoHuddleId}`}
                blank
                openInNewWindow
              />
            )}
          </article>
        </section>

        <nav className={Style.nav}>
          {activeView > 0 && (
            <Button
              icon="chevron-left"
              alignLeft
              color="dark"
              size={16}
              text="Prev"
              className={Style.prev}
              action={() => goTo(activeView - 1)}
            />
          )}

          {activeView < props.views.length - 1 && (
            <Button
              icon="chevron-right"
              alignRight
              color="dark"
              size={16}
              text="Next"
              className={Style.next}
              action={() => {
                activeView === 0
                  ? generateHuddles(activeView + 1)
                  : goTo(activeView + 1);
              }}
            />
          )}

          {activeView === props.views.length - 1 && (
            <Button
              icon="check-circle"
              alignRight
              color="dark"
              size={16}
              text="Finish"
              className={Style.next}
              action={() => {
                Event('completed_onboarding');
                finish();
              }}
            />
          )}
        </nav>
      </div>
    </Animate>
  );
}
