import { Dashboard } from 'views/dashboard/dashboard';
import { RecordCreate } from 'views/recordCreate';
import { RecordsView } from 'views/recordsView';
import { RecordView } from 'views/recordView';
import { HuddleCreate } from 'views/huddleCreate';
import { HuddlesView } from 'views/huddlesView';
import { HuddleView } from 'views/huddleView';
import { Help } from 'views/dashboard/contact';
import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome',
  },
  {
    path: '/contact',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Contact',
  },
  {
    path: '/record',
    view: RecordCreate,
    layout: 'app',
    permission: 'user',
    title: 'Create Recording',
  },
  {
    path: '/records',
    view: RecordsView,
    layout: 'app',
    permission: 'user',
    title: 'Recordings Library',
  },
  {
    path: '/record/:id',
    view: RecordView,
    layout: 'app',
    permission: 'user',
    title: 'View Recording',
  },
  {
    path: '/share/record/:id',
    view: RecordView,
    layout: 'app',
    permission: 'public',
    title: 'View Recording',
  },
  {
    path: '/huddle',
    view: HuddleCreate,
    layout: 'app',
    permission: 'user',
    title: 'Create Huddle',
  },
  {
    path: '/huddles',
    view: HuddlesView,
    layout: 'app',
    permission: 'user',
    title: 'Huddles Library',
  },
  {
    path: '/huddle/:id',
    view: HuddleView,
    layout: 'app',
    permission: 'user',
    title: 'Edit Huddle',
  },
  {
    path: '/share/huddle/:id',
    view: HuddleView,
    layout: 'app',
    permission: 'public',
    title: 'View Huddle',
  },
];

export default Routes;
