import React from 'react';

export function Tooltip({ children, text, className }) {
  return (
    <div className="relative flex flex-col group">
      {children}
      <div
        className={`absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex ${className}`}
      >
        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg w-32 text-center">
          {text}
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
      </div>
    </div>
  );
}
