import React, { Fragment } from 'react';
import { Card, Loader, useAPI, Image } from 'components/lib';
import Img5 from '../dashboard/images/img5.svg';

export function Help(props) {
  const user = useAPI('/api/user');

  return (
    <Fragment>
      {user.loading ? (
        <Loader />
      ) : (
        <Card title="Friendly Support 🙂" restrictWidth>
          <Image source={Img5} className="w-96 -pt-16" />
          <div className="text-center">
            <p className="pb-6">
              Questions, bugs or <b>feature requests</b>?
            </p>
            <a href="mailto:contact@jumpasync.com" className="text-blue-500 ">
              contact@jumpasync.com
            </a>
            <p className="pt-6">We wil get back to you within 24 hours.</p>
          </div>
        </Card>
      )}
    </Fragment>
  );
}
