/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  AuthContext,
  Animate,
  Row,
  Card,
  SocialSignin,
  Form,
  Link,
} from 'components/lib';
import emailToName from 'email-to-name';

export function Signup(props) {
  const context = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';

  return (
    <Animate type="pop">
      <Row title="Create Your Account">
        <Card loading={false} restrictWidth center>
          <SocialSignin network={['google']} showOr signup />

          <Form
            data={{
              name: {
                label: 'Name',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your name',
                value: emailToName.process(email) || '',
              },
              email: {
                label: 'Email',
                type: 'email',
                required: true,
                value: email || '',
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
            }}
            url="/api/account"
            method="POST"
            buttonText="Create Account"
            callback={context.signin}
          />

          <div className="mt-4">
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
