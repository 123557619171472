/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

import React from 'react';
import Spinner from './images/spinner.png';
import { ClassHelper } from 'components/lib';
import Style from './loader.tailwind.js';

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, props);

  return (
    <div className={loaderStyle}>
      <img src={Spinner} className={Style.orbit} alt="Loading Spinner" />
    </div>
  );
}
