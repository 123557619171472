import React from 'react';
import { Hero } from 'components/lib';
import Img1 from './images/1-jumpasync-huddles.png';

export function Home() {
  return (
    <>
      <Hero
        title="Meetings without being online at the same time"
        tagline="Work asynchronously and remotely across distributed teams and timezones, without distractions from scheduled meetings."
        image={Img1}
      />
    </>
  );
}
