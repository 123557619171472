import React from 'react';
import moment from 'moment';
import Avatar from 'boring-avatars';
import { useNavigate } from 'react-router-dom';
import { useAPI, Loader, Button } from 'components/lib';
import parse from 'html-react-parser';

export function HuddlesView() {
  const data = useAPI(`/api/huddle`);
  const navigate = useNavigate();

  return data.loading ? (
    <Loader />
  ) : (
    <div className="mt-6 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
      {data.data?.length < 1 && <Button text="Create Huddle" goto="/huddle" />}
      {data.data?.map((huddle, key) => (
        <div
          key={key}
          className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer"
          onClick={() => navigate(`../share/huddle/${huddle._id}`)}
        >
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <p className="text-sm font-medium text-indigo-600">
                {huddle?.goal}
              </p>
              <p className="text-xl font-semibold text-gray-900">
                {huddle?.topic}
              </p>
              <p className="mt-3 text-base text-gray-500 line-clamp-5">
                {parse(huddle?.agenda)}
              </p>
            </div>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <span className="sr-only">{huddle?.createdByName}</span>
                <Avatar
                  size={40}
                  name={huddle?.createdByName}
                  variant="beam"
                  colors={[
                    '#fb7185',
                    '#fbbf24',
                    '#34d399',
                    '#38bdf8',
                    '#a78bfa',
                  ]}
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">
                  {huddle?.createdByName}
                </p>
                <div className="flex space-x-1 text-sm text-gray-500">
                  <time dateTime={huddle?.date_created}>
                    {moment(huddle?.date_created).calendar()}
                  </time>
                  <span aria-hidden="true">&middot;</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
