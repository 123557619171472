import { useContext } from 'react';
import Axios from 'axios';
import { Player, Video } from '@vime/react';
import Avatar from 'boring-avatars';
import { BookmarkIcon } from '@heroicons/react/solid';
import { TwCommentInput, ViewContext } from 'components/lib';

export function Records({
  recordsData,
  isShare,
  participantEmail,
  participantName,
}) {
  const context = useContext(ViewContext);

  const pinToAgenda = (record) => {
    console.log('record ', record);
    console.log('record ._id ', record._id);
    Axios.patch(`/api/record${isShare ? '/share' : ''}/${record._id}`, {
      pinned: !record.pinned,
    }).then(async () => {
      context.notification.show(
        'Your comment have been submitted successfully',
        'success',
        true
      );
      window.location.reload(false);
    });
  };

  return (
    <div className="mx-8 mt-2 max-w-7xl mx-auto md:grid md:grid-cols-2">
      {recordsData.map((record, key) => (
        <>
          <div
            key={key}
            className="py-6 px-4 sm:px-6 md:flex md:flex-col md:py-6 md:pl-0 md:pr-10 lg:pr-16"
          >
            <blockquote className="mt-6 md:flex md:flex-col">
              <div
                onClick={() => window.open(`../record/${record._id}`, '_blank')}
                className="cursor-pointer mt-3"
              >
                <div className="flex">
                  <div className="flex-shrink-0 inline-flex rounded-full">
                    <Avatar
                      size={50}
                      name={
                        record?.participantName ? record?.participantName : ''
                      }
                      variant="beam"
                      colors={[
                        '#f9a8d4',
                        '#fbbf24',
                        '#34d399',
                        '#38bdf8',
                        '#a78bfa',
                      ]}
                    />
                  </div>
                  <div className="ml-1">
                    <div className="text-lg font-semibold">{record?.title}</div>
                    {record?.participantName && (
                      <div className="text-sm text-blue-200">
                        {record?.participantName}
                      </div>
                    )}
                  </div>
                </div>
                {record?.transcript && (
                  <div className="mt-4 ml-4 relative text-sm md:flex-grow">
                    <svg
                      className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-200"
                      fill="currentColor"
                      viewBox="0 0 64 64"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="-mt-2 relative line-clamp-2">
                      {record?.transcript}
                    </p>
                  </div>
                )}
                <Player>
                  <Video>
                    <source data-src={record?.signedFileUrl} type="video/mp4" />
                  </Video>
                </Player>
              </div>
              <div
                className="flex mt-2 cursor-pointer z-20 w-32"
                onClick={() => pinToAgenda(record)}
              >
                {recordsData.length === 1 && !record.pinned && (
                  <span className="-relative inline-flex rounded-full h-4 w-4 bg-blue-500 animate-ping -ml-4" />
                )}
                <BookmarkIcon className="h-8 w-7 text-blue-500 -ml-2" />
                <span className="text-md mt-1 font-semibold">
                  {record.pinned ? 'Remove pin' : 'Pin to agenda'}
                </span>
              </div>
              <div className="-mt-8">
                <TwCommentInput
                  recordId={record._id}
                  isShare={isShare}
                  comments={record.comments}
                  participantEmail={participantEmail}
                  participantName={participantName}
                />
              </div>
            </blockquote>
          </div>
        </>
      ))}
    </div>
  );
}
