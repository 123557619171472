/***
 *
 *   LOGO
 *   Replace the image in /images with your own logo
 *
 **********/

import React from 'react';
import { Link, ClassHelper } from 'components/lib';
import LogoWhite from './images/logo-white.png';
import LogoMarkWhite from './images/logo-mark-white.png';
import LogoColor from './images/logo-color.png';
import LogoMarkColor from './images/logo-mark-color.png';
import Style from './logo.tailwind.js';

export function Logo(props) {
  const Logo = {
    color: {
      logo: LogoColor,
      mark: LogoMarkColor,
    },
    white: {
      logo: LogoWhite,
      mark: LogoMarkWhite,
    },
  };

  const logoStyle = ClassHelper(Style, props);

  return (
    <div url="/" className={props.icon ? logoStyle : 'flex no-underline'}>
      {props.icon}
      <img
        src={
          Logo[props.color ? 'color' : 'white'][props.mark ? 'mark' : 'logo']
        }
        alt="Logo"
        className={props.icon ? 'w-8' : 'w-10 pt-6'}
      />
      {!props.icon && (
        <span className="pt-8 pl-2 text-xl font-bold text-white">
          JumpAsync
        </span>
      )}
    </div>
  );
}
