import {
  ChatAltIcon,
  ThumbUpIcon,
  HeartIcon,
  EmojiHappyIcon,
  EmojiSadIcon,
  FireIcon,
} from '@heroicons/react/solid';
import Avatar from 'boring-avatars';
import moment from 'moment';

export function TwCommentsList({ comments }) {
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {comments?.map((item, key) => (
          <li key={key}>
            <div className="relative pb-8">
              <div className="relative flex items-start space-x-3">
                <>
                  <div
                    className="relative cursor-pointer"
                    title={item?.userEmail}
                  >
                    <Avatar
                      name={item?.userName}
                      variant="beam"
                      colors={[
                        '#f9a8d4',
                        '#fbbf24',
                        '#34d399',
                        '#38bdf8',
                        '#a78bfa',
                      ]}
                      className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white cursor-pointer"
                    />

                    <span className="absolute bg-white -bottom-2 -right-2 rounded-xl px-0.5 py-px">
                      {item?.emoji === 'like' ? (
                        <ThumbUpIcon className="w-5 h-5 rounded-full flex items-center justify-center text-blue-500" />
                      ) : item?.emoji === 'love' ? (
                        <HeartIcon className="w-5 h-5 rounded-full flex items-center justify-center text-pink-400" />
                      ) : item?.emoji === 'happy' ? (
                        <EmojiHappyIcon className="w-5 h-5 rounded-full flex items-center justify-center text-yellow-500" />
                      ) : item?.emoji === 'sad' ? (
                        <EmojiSadIcon className="w-5 h-5 rounded-full flex items-center justify-center text-gray-400" />
                      ) : item?.emoji === 'hot' ? (
                        <FireIcon className="w-5 h-5 rounded-full flex items-center justify-center text-red-500" />
                      ) : (
                        <ChatAltIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <div>
                      <div className={`text-sm ${item?.comment ? '' : 'pt-2'}`}>
                        <div
                          className="text-md text-gray-500 cursor-pointer"
                          title={item?.userEmail}
                        >
                          {item?.userName}{' '}
                          <span className="text-sm text-gray-500 cursor-pointer">
                            {item?.comment ? 'commented ' : 'reacted '}
                            {moment(item?.date_created)
                              .calendar()
                              .toLocaleLowerCase()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="text-sm text-gray-700">
                      <p>{item?.comment}</p>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
