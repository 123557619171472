import React, { useState, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { AuthContext, useAPI, Link, Loader, Tooltip } from 'components/lib';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const isValidEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export function HuddleCreate() {
  // const [showModal, setShowModal] = useState(false);
  // const [huddleId, setHuddleId] = useState('');
  const [liveEditorContent, setLiveEditorContent] = useState('');

  const editorRef = useRef(null);

  const auth = useContext(AuthContext);

  const data = useAPI(`/api/huddle`);
  const noOfHuddles = data?.data?.length;

  const navigate = useNavigate();

  let canCreateHuddle = false;
  if (auth.user.plan === 'free' && noOfHuddles < 6) {
    canCreateHuddle = true;
  } else if (
    auth.user.plan === 'price_1KqrlfCye1XYEUBinOL6moIy' &&
    noOfHuddles < 51
  ) {
    canCreateHuddle = true;
  } else if (
    auth.user.plan === 'price_1KqrlfCye1XYEUBiw4T2ndKV' &&
    noOfHuddles < 501
  ) {
    canCreateHuddle = true;
  } else if (auth.user.plan === 'price_1KqrlfCye1XYEUBi2wlSLVgp') {
    canCreateHuddle = true;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onEditorChange = () => {
    let savedNotes;
    if (editorRef.current) {
      savedNotes = editorRef.current.getContent();
      setLiveEditorContent(savedNotes);
    }
  };

  const onSubmit = (data) => {
    Axios.post('/api/huddle', {
      ...data,
      createdByEmail: auth?.user?.email,
      createdByName: auth?.user?.name,
      agenda: liveEditorContent,
    }).then(
      (response) => {
        // setHuddleId(response.data.data);
        navigate(`/share/huddle/${response.data.data}&new=true`);
      }
      // (error) => {
      //   console.log(error);
      // }
    );
  };

  const handleEmailValidation = (emailsStr) => {
    if (emailsStr === '' || null) {
      return true;
    }
    const emailsArr = emailsStr
      .replace(/,+$/, '')
      .replace(/\s/g, '')
      .split(',');
    let validateEmails = [];
    emailsArr.forEach((email) => {
      const isValid = isValidEmail(email);
      validateEmails.push(isValid);
    });
    if (validateEmails.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  return canCreateHuddle ? (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-8 divide-y divide-gray-200"
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Create huddle
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              This information will be displayed on your huddle page.
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <div className="flex">
                <label
                  htmlFor="topic"
                  className="block text-md font-medium text-gray-700"
                >
                  Topic
                </label>
                <Tooltip
                  text="A few words about the huddle subject."
                  className="-ml-14"
                >
                  <QuestionMarkCircleIcon className="w-4" />
                </Tooltip>
              </div>
              <div className="mt-1">
                <input
                  {...register('topic', { required: true, maxLength: 100 })}
                  type="text"
                  name="topic"
                  id="topic"
                  className={`${
                    errors.topic &&
                    `border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-600 focus:border-red-500`
                  } my-1 leading-8 px-2 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-md border border-gray-300 rounded-md`}
                />
                {errors?.topic?.type === 'required' && (
                  <p className="text-xs text-red-600 mt-2">
                    This field is required
                  </p>
                )}
                {errors?.topic?.type === 'maxLength' && (
                  <p className="text-xs text-red-600 mt-2">
                    You have exceeded the maximum length of 100 characters
                  </p>
                )}
              </div>
            </div>

            <div className="sm:col-span-1">
              <div className="flex">
                <label
                  htmlFor="goal"
                  className="block text-md font-medium text-gray-700"
                >
                  Goal
                </label>
                <Tooltip
                  text="What is the purpose of this huddle?"
                  className="-ml-14"
                >
                  <QuestionMarkCircleIcon className="w-4" />
                </Tooltip>
              </div>
              <div className="mt-1">
                <select
                  {...register('goal', { required: true })}
                  id="goal"
                  name="goal"
                  className={`${
                    errors.goal &&
                    `border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-600 focus:border-red-500`
                  } my-1 h-8 px-2 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-md border border-gray-300 rounded-md`}
                >
                  <option></option>
                  <option>Brainstorm</option>
                  <option>Discuss</option>
                  <option>Decide</option>
                </select>
                {errors.goal && (
                  <p className="text-xs text-red-600 mt-2">
                    This field is required
                  </p>
                )}
              </div>
            </div>

            <div className="sm:col-span-3">
              <div className="flex">
                <label
                  htmlFor="participants"
                  className="block text-md font-medium text-gray-700"
                >
                  Invite Teammates / Clients
                </label>
                <Tooltip
                  text="People who are invited can attend the huddle but cannot edit the agenda and details."
                  className="-ml-14"
                >
                  <QuestionMarkCircleIcon className="w-4" />
                </Tooltip>
                <span className="text-xs ml-2">
                  enter multiple email addresses at once, separated with a comma
                </span>
              </div>
              <div className="mt-1">
                <textarea
                  {...register('participants', {
                    maxLength: 650,
                    validate: handleEmailValidation,
                  })}
                  type="text"
                  name="participants"
                  id="participants"
                  className={`${
                    errors.participants &&
                    `border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-600 focus:border-red-500`
                  } my-1 leading-8 px-2 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-md border border-gray-300 rounded-md`}
                />
                {errors?.participants?.type === 'maxLength' && (
                  <p className="text-xs text-red-600 mt-2">
                    You have exceeded the maximum length of 650 characters
                  </p>
                )}
                {errors?.participants?.type === 'validate' && (
                  <p className="text-xs text-red-600 mt-2">
                    Invalid email address(es)
                  </p>
                )}
              </div>
            </div>

            <div className="sm:col-span-1">
              <div className="flex">
                <label
                  htmlFor="ends"
                  className="block text-md font-medium text-gray-700"
                >
                  Ends In
                </label>
                <Tooltip
                  text="When should the huddle end? Dateline for invitees to paticipate in the huddle."
                  className="-ml-14"
                >
                  <QuestionMarkCircleIcon className="w-4" />
                </Tooltip>
              </div>
              <div className="mt-1">
                <select
                  {...register('ends')}
                  id="ends"
                  name="ends"
                  className={`${
                    errors.ends &&
                    `border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-600 focus:border-red-500`
                  } my-1 h-8 px-2 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-md border border-gray-300 rounded-md`}
                >
                  <option></option>
                  <option>3 days</option>
                  <option>1 week</option>
                  <option>2 weeks</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-4">
              <div className="flex">
                <label
                  htmlFor="agenda"
                  className="block text-md font-medium text-gray-700"
                >
                  Agenda
                </label>
                <Tooltip
                  text="Provide an outline of items to be addressed in the huddle."
                  className="-ml-14"
                >
                  <QuestionMarkCircleIcon className="w-4" />
                </Tooltip>
              </div>
              <div className="mt-1">
                <Editor
                  apiKey="k8kdoio4fwqd3woe1acxlhsn4r3n6kmwmb47goyv2goiwena"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={onEditorChange}
                  initialValue=""
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'wordcount',
                      'autosave',
                      'save',
                    ],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Source Sans Pro,Helvetica,sans-serif; font-size:12.5px }',
                    branding: false,
                  }}
                />
                {!liveEditorContent && (
                  <p className="text-xs text-red-600 mt-2">
                    This field is required
                  </p>
                )}
                {liveEditorContent.length > 3000 && (
                  <p className="text-xs text-red-600 mt-2">
                    You have exceeded the maximum length of 3000 characters
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-start">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-md font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
      {/* {showModal && (
        <TwModal
          title="Huddle created"
          btnLeftLabel="Invite"
          btnLeftLink={`/huddle/${huddleId}&invite=true`}
          btnRightLabel="Go to Huddle"
          btnRightLink={`/huddle/${huddleId}`}
          closeModal={() => setShowModal(false)}
          status="success"
        />
      )} */}
    </form>
  ) : data.loading ? (
    <Loader />
  ) : (
    <p>
      You have exceeded the maximum number of huddles, please{' '}
      <Link url="/account/billing">upgrade your plan</Link>
      {'  '} to create more.
    </p>
  );
}
