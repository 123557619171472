/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext, useState } from 'react';
import {
  AuthContext,
  Onboarding,
  Message,
  Button,
  Image,
} from 'components/lib';
import Img1 from '../dashboard/images/img1.svg';
import Img2 from '../dashboard/images/img2.svg';
import Img3 from '../dashboard/images/img3.svg';
import Img4 from '../dashboard/images/img4.svg';

export function OnboardingView(props) {
  const [job, setJob] = useState('');

  const context = useContext(AuthContext);
  const views = [
    {
      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome onChange={(e) => setJob(e.target.value)} />,
    },
    {
      name: 'Join Huddle',
      description: `Create your first async meeting and collaborate with your team`,
      component: <CreateHuddle />,
    },
    {
      name: 'Create Recording',
      description: `Create your first recording and share it`,
      component: <CreateRecording />,
    },
    // context.permission.admin && {
    //   name: 'Invite your team',
    //   description: 'JumpAsync is more fun when you invite your team.',
    //   component: <InviteUsers />,
    // },
    {
      name: 'Completed',
      description: `All set and ready to go, ${context.user.name}!`,
      component: <Complete />,
    },
  ];

  if (context.user.duplicate_user) {
    views.unshift({
      name: 'Important!',
      description: '',
      component: <DuplicateUser />,
    });
  }

  return <Onboarding save onFinish="/dashboard" views={views} job={job} />;
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  );
}

function Welcome({ onChange }) {
  return (
    <Fragment>
      <p>
        <strong>Welcome to JumpAsync!</strong>
      </p>
      <p>
        We're glad that you're here and would like to get you started in a few
        simple steps. Which of the following best describes you?
      </p>
      <select
        id="location"
        name="location"
        className="p-2 rounded-md text-base sm:text-md border-2"
        onChange={onChange}
      >
        <option disabled selected hidden>
          Select Role
        </option>
        <option value="Software Engineer">Software Engineer</option>
        <option value="Marketer">Marketer</option>
        <option value="Recruiter">Recruiter</option>
        <option value="Educator">Educator</option>
        <option value="Designer">Designer</option>
        <option value="Other">Other</option>
      </select>
      <Image source={Img3} className="w-96 -pt-16" />
    </Fragment>
  );
}

// function InviteUsers(props) {
//   return (
//     <Form
//       data={{
//         email: {
//           label: 'Email',
//           type: 'email',
//           required: true,
//         },
//       }}
//       buttonText="Send Invite"
//       url="/api/invite"
//       method="POST"
//     />
//   );
// }

function CreateHuddle() {
  return (
    <Fragment>
      <p>
        Thanks for letting us know, we have created a demo huddle for you. It
        takes only a minute to see how it works.
      </p>
      <Image source={Img1} className="w-96 -mt-12" />
    </Fragment>
  );
}

function CreateRecording() {
  return (
    <Fragment>
      Do you know that you can also record presentations and share it easily
      with just a link? It takes just 5 seconds to try it.
      <Image source={Img2} className="w-80" />
      <Button text="Record, GO!" goto="/record" blank openInNewWindow />
    </Fragment>
  );
}

function Complete() {
  return (
    <Fragment>
      <p>
        <strong>Well done! </strong>
        You have created your first huddle, it's that easy 🥳
      </p>
      <Image source={Img4} className="w-80 -pt-16" />
      <p>
        Should you have feature requests or need help at any point reach out at
        contact@jumpasync.com 😊
      </p>
    </Fragment>
  );
}
