import React from 'react';
import { Link, Button, Image } from 'components/lib';
import Img1 from './images/img1.svg';
import Img2 from './images/img2.svg';

export function Dashboard(props) {
  return (
    <div className="max-w-lg mx-auto -px-8">
      <h2 className="text-center text-2xl md:text-3xl font-semibold text-blue-700">
        Welcome to JumpAsync!
      </h2>
      <div className="text-center flex flex-col md:flex-row justify-center">
        <div className="py-4 md:px-8">
          <p className="text-xl mt-8 font-semibold">Start a meeting</p>
          <p>Create a huddle and invite teammate(s)</p>
          <Image source={Img1} className="w-full md:w-96" />
          <Button
            text="Create Huddle"
            goto="/huddle"
            className="w-full md:w-96"
          />
        </div>

        <div className="py-4 md:px-8">
          <p className="text-xl mt-8 font-semibold">Record a presentation</p>
          <p>And share it easily with just a link</p>
          <Image source={Img2} className="w-full md:w-96 mb-10" />
          <Button
            text="Create Recording"
            goto="/record"
            className="w-full md:w-96"
          />
        </div>
      </div>

      <div className="text-lg mt-8 font-semibold text-center">
        Need help or have any feature requests?{' '}
        <Link url="/contact">Contact us</Link>
      </div>
    </div>
  );
}
