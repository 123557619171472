import React, { useContext, useState, useEffect } from 'react';
import {
  EyeIcon,
  EyeOffIcon,
  ShareIcon,
  TrashIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/solid';
import { Player, Video } from '@vime/react';
import { Link, Loader, useAPI, ViewContext, Button } from 'components/lib';

export function RecordsView(props) {
  const context = useContext(ViewContext);
  const data = useAPI('/api/record');
  const [recordings, setRecordings] = useState([]);

  useEffect(() => {
    setRecordings(data.data);
  }, [data]);

  const shareRecording = (data) => {
    context.modal.show(
      {
        title: 'Share Recording',
        form: {
          id: {
            type: 'hidden',
            value: data._id,
          },
          permission: {
            label: `Permission for ${data.title}`,
            type: data.permission === 'owner' ? null : 'select',
            options: [
              {
                label: 'Public (anyone with the link can view)',
                value: 'public',
              },
              { label: 'Private (only you can view)', value: 'private' },
            ],
            default: data.permission,
          },
        },
        buttonText: 'Update',
        titleAddOn: data.permission === 'public' && (
          <div className="inline-flex items-center w-56">
            <button
              type="button"
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.host}/recording/share/${data._id}`
                )
              }
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ClipboardCopyIcon className={ICN_STYLE_SM} /> Copy link
            </button>
          </div>
        ),
        url: `/api/record/${data._id}`,
        method: 'PATCH',
      },
      () => {
        context.notification.show(`Record was updated`, 'success', true);
        window.location.reload(false);
      }
    );
  };

  const deleteRecording = (data, callback) => {
    context.modal.show(
      {
        title: 'Delete Recording',
        form: {},
        buttonText: 'Delete',
        text: `Are you sure you want to delete recording '${data.title}'?`,
        url: `/api/record/${data._id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(
          `'${data.title}' recording was deleted`,
          'success',
          true
        );
        window.location.reload(false);
      }
    );
  };

  const ICN_STYLE_SM = 'h-4 w-4 text-blue-500 mr-2';
  const ICN_STYLE_SM_BLACK = 'h-4 w-4 text-black-500 mr-2';

  return (
    <>
      {data.loading && <Loader />}
      <div
        role="list"
        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 "
      >
        {recordings?.length < 1 && (
          <Button text="Create Recording" goto="/record" />
        )}
        {recordings &&
          recordings
            .sort(
              (d1, d2) =>
                new Date(d2.date_created).getTime() -
                new Date(d1.date_created).getTime()
            )
            .map((record) => (
              <div key={record._id} className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-white overflow-hidden border-2 border-grey-500/50">
                  <Link
                    url={`/record/${record._id}`}
                    className="cursor-pointer"
                  >
                    <Player>
                      <Video>
                        <source
                          data-src={record.signedFileUrl}
                          type="video/mp4"
                        />
                      </Video>
                    </Player>
                  </Link>
                  {}
                  <div className="pt-2.5 flex">
                    <Link
                      url={`/record/${record._id}`}
                      className="cursor-pointer pl-2.5 block text-lg font-medium text-gray-900 truncate"
                    >
                      {record.title}
                    </Link>
                    {record.permission === 'public' ? (
                      <EyeIcon className={`${ICN_STYLE_SM_BLACK} ml-2`} />
                    ) : (
                      <EyeOffIcon className={`${ICN_STYLE_SM_BLACK} ml-2`} />
                    )}
                  </div>
                  <div className="px-2 pb-2">
                    <button
                      title="Share"
                      type="button"
                      className="-ml-px relative inline-flex items-center pl-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                      onClick={() => shareRecording(record)}
                    >
                      <ShareIcon className={ICN_STYLE_SM} />
                    </button>
                    {/* <button
                      title="Download"
                      type="button"
                      className="-ml-px relative inline-flex items-center pl-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      onClick={() => downloadRecording(record.signedFileUrl)}
                    >
                      <DownloadIcon className={ICN_STYLE_SM} />
                    </button> */}
                    <button
                      title="Delete"
                      type="button"
                      className="-ml-px relative inline-flex items-center pl-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                      onClick={() => deleteRecording(record)}
                    >
                      <TrashIcon className={ICN_STYLE_SM} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
}
